:root {
  --mc1: rgb(45, 27, 113);
  --mc2: #c8dfff;
  --hff: "Stick", sans-serif;
  --bff: "Stick", sans-serif;
  --bs-body-font-family: "Stick", sans-serif;
}
@font-face {
  font-family: "Chiller";
  src: url("../fonts/CHILLER-FONT/CHILLER.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
p {
  font-family: "Risque", cursive;
}
.risque {
  font-family: "Risque", cursive;
}
.chiller {
  font-family: "Chiller", sans-serif;
}
body {
  font-family: "Stick", sans-serif !important;
  background: rgb(7, 4, 49);
  /*background: linear-gradient(90deg, rgba(7, 4, 49, 1) 0%, rgba(1, 163, 244, 1) 50%, rgba(7, 4, 49, 1) 100%);*/
  background: linear-gradient(
    90deg,
    rgba(7, 4, 49, 1) 0%,
    rgba(45, 27, 113, 1) 50%,
    rgba(7, 4, 49, 1) 100%
  );
}

.uneditable-input,
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  font-family: "Stick", sans-serif;
}

.header.active {
  background: rgb(7, 4, 49);
  background: linear-gradient(
    90deg,
    rgba(7, 4, 49, 1) 0%,
    rgba(45, 27, 113, 1) 50%,
    rgba(7, 4, 49, 1) 100%
  );
}

.metaverse_fn_preloader {
  background: rgb(7, 4, 49);
  background: linear-gradient(
    90deg,
    rgba(7, 4, 49, 1) 0%,
    rgba(45, 27, 113, 1) 50%,
    rgba(7, 4, 49, 1) 100%
  );
}

.about_img .top {
  color: rgba(255, 255, 255, 0.2);
}

.fn_cs_roadmap .roadmap_bottom .item_in {
  background-color: var(--mc2);
}
.fn_cs_roadmap .roadmap_bottom .phase h3 {
  color: var(--bs-body-color);
}

.hero_header .buttons a {
  border-radius: 30px;
}

.metaverse_fn_button {
  border-radius: 30px;
}

.metaverse_fn_button .text {
  border-radius: 30px;
}

.metaverse_fn_button:before,
.metaverse_fn_button:after {
  border-left: none;
  border-right: none;
}

.gradient_link {
  background: none;
  border: 0px;
}

.bottom_icon .bottom {
  color: rgba(255, 255, 255, 0.2);
}

.about_img .bottom {
  /*color: rgba(255, 255, 255, .2);*/
  color: purple;
}

#video {
  padding: 50px 0 50px;
  border-bottom: none;
}
#roadmap {
  padding: 50px 0;
}

.about_img img {
  border-radius: 10px 10px 0px 0px;
  border: 9px solid purple;
}

.fn_cs_video img {
  border-radius: 20px;
}

.menu_btn:after {
  z-index: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 30px;
  /*border: 2px solid #ffffff;*/
  position: absolute;
  content: "";
}

.menu_btn:before {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0px;
  /*z-index: 1;*/
  content: "";
  color: #000 !important;
  /*background: var(--mc2);*/
  background: transparent;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.gradient_link:hover {
  color: #c8dfff !important;
}

.connect_btn:before {
  background: transparent;
}

.connect_btn:after {
  /* border: 2px solid #ffffff; */
  border: none;
}

.connect_btn:hover {
  color: #c8dfff !important;
}

.trigger:after {
  border: 3px solid #ffffff;
}

#team {
  padding: 0px 0 160px;
}

.fn_cs_contact_form input[type="text"] {
  height: 45px;
}
textarea {
  height: 145px;
}

#firstHeader {
  position: relative;
  padding: 90px 0 50px;
}
#home {
  padding: 0px 0 274px;
}
#prosperity {
  padding: 150px 0 0;
}
#prosperity img {
  border: 3px solid var(--mc2);
  border-radius: 10px;
}
#mission {
  padding: 50px 0;
}
#mission img {
  border: 3px solid var(--mc2);
  border-radius: 10px;
}
#education {
  padding: 50px 0;
  background-color: var(--mc2);
}
#education img {
  border: 3px solid rgb(45, 27, 113);
  border-radius: 10px;
}
#ngo {
  padding: 50px 0;
}
#ngo img {
  border: 3px solid var(--mc2);
  border-radius: 10px;
}
#talent {
  padding: 50px 0;
  background-color: var(--mc2);
}
#talent img {
  border: 3px solid rgb(45, 27, 113);
  border-radius: 10px;
}
.font-100 {
  font-size: 100px;
}
.font-70 {
  font-size: 70px;
}
.font-45 {
  font-size: 45px;
}

.home__svg {
  width: 30px;
  height: 30px;
}

.bsc-select {
  line-height: 34px;
  padding: 0 48px 0 16px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  border-radius: 50px !important;
  width: 135px !important;
}

.code-wrap {
  font-family: "Readex Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  margin: 0 32px 0 24px;
}

.buy-icon {
  height: 28px;
  width: 28px;
  -o-object-fit: contain;
  object-fit: contain;
  pointer-events: none;
  margin-right: 12px;
}

.connect_btn:after {
  border-radius: 12px;
}

.hero_header .hero_left {
  width: 100%;
  max-width: 650px;
}

/*.fn_cs_title .divider[data-color="1"] .line:after, .fn_cs_title .divider[data-color="1"] .line:before, .fn_cs_title .divider[data-color="1"] div {*/
/*    background-color: rgb(45, 27, 113);*/
/*}*/

/*#about {*/
/*    background-color: #c8dfff;*/
/*}*/

/*.section_divider .st1 {*/
/*    color: #c8dfff;*/
/*}*/
@media (max-width: 1200px) {
  .menu_btn {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    text-decoration: none;
    font-family: var(--hff);
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: uppercase;
    min-height: 45px;
    max-height: 45px;
    padding: 10px 30px;
    text-align: center;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    position: relative;
    border-radius: 30px;
  }
  .connect_btn {
    padding: 0px 16px;
    border-radius: 12px;
    height: 56px;
  }
  .logo {
    width: 30%;
  }
  .hero_left .buttons {
    flex-wrap: unset;
    flex-direction: inherit;
  }
  .module-wrap .form-select {
    margin-left: 0px;
  }
  .module-wrap {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    padding: 7px 17px 7px 7px;
    margin-top: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 610px;*/
    width: 550px;
  }

  .mypadone {
    padding-left: 1px;
    padding-right: 1px;
  }
  /*.menu_btn {*/
  /*    font-size: 10px;*/
  /*}*/

  /*.connect_btn {*/
  /*    font-size: 10px;*/
  /*}*/

  /*.connect_btn img {*/
  /*    height: 11px;*/
  /*}*/

  #firstHeader {
    padding: 1px 0 20px;
  }
  .right_part {
    width: 100%;
  }

  .mob-list {
    justify-content: center;
  }

  .mob-list li {
    margin-left: 0px;
  }
}

@media (max-width: 1040px) {
  .home-cat {
    height: 245px;
  }
}

@media (min-width: 320px) {
  .connect_btn {
    padding: 0px 10px;
    border-radius: 12px;
    height: 45px;
  }
  .logo {
    width: 50%;
    color: #fff;
    font-family: "Chiller";
    font-size: 20px;
  }
  .module-wrap {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    padding: 7px 17px 7px 7px;
    margin-top: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 610px;*/
    width: 100%;
  }
  .module-wrap .form-select {
    margin-left: 25%;
  }
  .mypadone {
    padding-left: 1px;
    padding-right: 1px;
  }
}
@media (min-width: 480px) {
  .connect_btn {
    padding: 0px 10px;
    border-radius: 12px;
    height: 45px;
  }
  .logo {
    width: 40%;
  }
  .module-wrap {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    padding: 7px 17px 7px 7px;
    margin-top: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 610px;*/
    width: 100%;
  }
  .mypadone {
    padding-left: 1px;
    padding-right: 1px;
  }
}
@media (min-width: 600px) {
  .connect_btn {
    padding: 0px 10px;
    border-radius: 12px;
    height: 45px;
  }
  .logo {
    width: 40%;
  }
  .module-wrap {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    padding: 7px 17px 7px 7px;
    margin-top: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 610px;*/
    width: 100%;
  }
  .mypadone {
    padding-left: 1px;
    padding-right: 1px;
  }
}
@media (min-width: 801px) {
  .connect_btn {
    padding: 0px 10px;
    border-radius: 12px;
    height: 45px;
    border: 3px solid #ffffff;
  }
  .logo {
    width: 30%;
  }
  .module-wrap .form-select {
    margin-left: 0px;
  }
  .module-wrap {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    padding: 7px 17px 7px 7px;
    margin-top: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 610px;*/
    width: 100%;
  }
  .mypadone {
    padding-left: 1px;
    padding-right: 1px;
  }
}
@media (min-width: 1025px) {
  .logo {
    width: 14%;
  }
  .menu_btn {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    text-decoration: none;
    font-family: var(--hff);
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: uppercase;
    min-height: 45px;
    max-height: 45px;
    padding: 10px 12px;
    text-align: center;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    position: relative;
    border-radius: 30px;
  }
  .module-wrap .form-select {
    margin-left: 0px;
  }
  .module-wrap {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    padding: 7px 17px 7px 7px;
    margin-top: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 610px;*/
    width: 100%;
  }
  .mypadone {
    padding-left: 1px;
    padding-right: 1px;
  }
}

.whitepaperbtn {
  border: 3px solid#ffffff !important;
}
